import ModalWrapper from 'components/modal/modal-wrapper';
import { withAPI } from 'hocs';
import _ from 'lodash';
import React from 'react';

const ServiceStatusModal = ({ open, setOpen, serviceStatus }) => {
  return (
    <ModalWrapper open={open} setOpen={setOpen} title="System Status">
      <div className="flex gap-4 mt-2 flex-col">
        <h2 className={`text-2xl font-semibold text-gray-900 text-center ${serviceStatus.status === 'FAIL' ? 'text-red-500' : 'text-indigo-600'}`}>
          {serviceStatus.status}
        </h2>
        <table className="w-full border divide-y divide-gray-200 mb-2">
          <thead className='bg-gray-50'>
            <tr>
              <th className="px-3 py-2 whitespace-nowrap border">Service</th>
              <th className="px-3 py-2 whitespace-nowrap border">Status</th>
              <th className="px-3 py-2 whitespace-nowrap border">Info</th>
            </tr>
          </thead>
          <tbody>
            {_.map(serviceStatus.items, (item) => (
              <tr key={item.name}>
                <td className="px-3 py-2 whitespace-nowrap border">{item.name}</td>
                <td className={`px-3 py-2 whitespace-nowrap border ${item.status === 'FAIL' ? 'text-red-500' : ''}`}>{item.status}</td>
                <td className="px-3 py-2 whitespace-nowrap border text-sm">{item.message ?? ''}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </ModalWrapper>
  )
}

export default _.flowRight([withAPI])(ServiceStatusModal);