import React from 'react';
import { UserIcon } from '@heroicons/react/outline';

const absoluteStyle = "absolute transform left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-max"

const NoResults = ({ centerContent = false, isVisible = true, customClass = '' }) => (
  <>
    {isVisible && (
      <div className={`flex flex-col items-center text-gray-600 mt-2 ${customClass} ${centerContent && absoluteStyle}`}>
        <UserIcon className="w-6 h-6" />
        <p>
          No results
        </p>
      </div>
    )}
  </>
);

export default NoResults;
