import React from 'react';
import PropTypes from 'prop-types';

const RadioButton = ({ label, value, onChange, isChecked, isDisabled }) => {
  return (
    <label className="sm:col-span-2 flex text-sm cursor-pointer">
      <div className={
        isChecked
          ? 'h-5 w-5 rounded-full bg-green-500 border-4 border-green-200'
          : 'h-5 w-5 rounded-full bg-gray-200 border-2 border-green-200'
      }
      />
      <span className="ml-3">{label}</span>
      <input
        className="hidden"
        type="radio"
        value={value}
        checked={isChecked}
        onChange={onChange}
        disabled={isDisabled}
      />
    </label>
  );
}

RadioButton.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]).isRequired,
  onChange: PropTypes.func.isRequired,
  isChecked: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
};

RadioButton.defaultProps = {
  isDisabled: false,
};

export default RadioButton;