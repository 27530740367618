import React from 'react';
import _ from 'lodash';

function withProjectVersion(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isVersionsLoading: false,
        version: {},
        serviceStatus: {},
      };
    }

    async componentDidMount () {
      await this.loadProjectVersions();
    }

    loadProjectVersions = async () => {
      const { httpRequest } = this.props;

      this.setState({ isVersionsLoading: true });

      const response = await httpRequest({
        method: 'get',
        url: '/health/check',
      }).finally(() => this.setState({ isVersionsLoading: false }));

      if (response) {
        const data = _.get(response, 'data.version', {});
        this.setState({ version: data, serviceStatus: {
          status: _.get(response, 'data.status', 'FAIL'),
          items: _.get(response, 'data.items', {}),
        }});
      }
    }


    render() {
      const { isVersionsLoading, version, serviceStatus } = this.state;
      const rest = {
        isVersionsLoading,
        version,
        serviceStatus,
      };
      return <WrappedComponent {...this.props} {...rest} />;
    }
  };
}

export default withProjectVersion;
