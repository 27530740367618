import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isNotMobile } from 'utils';

const FormInput = ({
  label, id, isRequired, value, handleValue, dataTestId,
  isDisabled, colspan, hint, type, placeholder, className,
  isTooltip, tooltipText, onMouseEnter, onMouseLeave, classNameInput = '',
}) => {
  const [isHovered, setIsHovered] = useState();

  return (
    <div
      className={`sm:col-span-${colspan} flex flex-col justify-between ${className}`}
      onMouseEnter={() => { onMouseEnter(); setIsHovered(true); }}
      onMouseLeave={() => { onMouseLeave(); setIsHovered(false)}}
    >
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label}
        {isRequired && (
          <sup className="text-red-regular">*</sup>
        )}
        {hint.length > 0 && (
          <span className="text-xs text-gray-500">
            {hint}
          </span>
        )}
      </label>
      <div className="mt-1">
        <input
          type={type}
          name={id}
          id={id}
          value={value || ''}
          disabled={isDisabled}
          data-testid={dataTestId}
          className={`shadow-sm focus:ring-indigo-500 focus:border-indigo-500
              block w-full sm:text-sm border-gray-300 rounded-md ${classNameInput}`}
          onChange={(e) => handleValue(id, e.target.value)}
          placeholder={placeholder}
        />
        {isNotMobile() && isTooltip && isHovered && (
          <div className="relative whitespace-pre-wrap">
            <div
              className="text-xs absolute z-10 left-1/2 top-0 z-2 py-2 px-4 bg-gray-100
                border border-black-100 rounded-2xl text-left block
                font-medium transform -translate-x-1/2 break-words w-full"
            >
              {tooltipText}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

FormInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  id: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  handleValue: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  dataTestId: PropTypes.string,
  isRequired: PropTypes.bool,
  colspan: PropTypes.number,
  hint: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

FormInput.defaultProps = {
  isRequired: false,
  isDisabled: false,
  dataTestId: '',
  colspan: 3,
  hint: '',
  type: 'text',
  placeholder: '',
  className: '',
  onMouseEnter: () => {},
  onMouseLeave: () => {},
};

export default FormInput;
