import { fireEvent, render, screen } from "@testing-library/react";
import { Route, Router } from "react-router-dom";
import { createMemoryHistory } from 'history';
import { setLocalStorage } from ".";
import userEvent from "@testing-library/user-event";
import qs from 'query-string';

export const setNotMobileWidth = () => {
  delete global.window.screen;
  global.window.screen = {
    availWidth: 1024,
  }
}

export const setMobileWidth = () => {
  delete global.window.screen;
  global.window.screen = {
    availWidth: 1000,
  }
}

export const onSelect = async (placeHolder = 'Select company', select = 'Test Company') => {
  const selectFields = screen.getAllByTestId('selected-drop-down');
  const selectCompanyField = selectFields.find((item) => item.textContent === placeHolder);

  fireEvent.click(selectCompanyField);
  const companies = await screen.findAllByRole('option');

  const company = companies.find((company) => company.textContent === select);
  fireEvent.click(company);
}

export const onClearMultiSelect = (positionAsChildEl = 0) => {
  const indicator = document.getElementsByClassName('select__indicators')[positionAsChildEl];
  const clearIndicator = indicator.firstChild;
  userEvent.click(clearIndicator);
}

export const setIntersectionObserver = () => {
  const mockIntersectionObserver = jest.fn();
  mockIntersectionObserver.mockReturnValue({
    observe: () => null,
    unobserve: () => null,
    disconnect: () => null
  });
  window.IntersectionObserver = mockIntersectionObserver;
}
export const renderWithRouterMatch = (
  ui,
  {
    path = "/",
    route = "/",
    state = {},
    history = createMemoryHistory({ initialEntries: [{ pathname: route, ...state }] })
  } = {}
) => {
  return {
    ...render(
      <Router history={history}>
        <Route path={path} component={ui} />
      </Router>
    )
  };
}
export const deletePermissions = () => {
  const profile = {
    data: {
      permissions: [],
    },
  }
  setLocalStorage('evl-profile', profile);
}
export const setPermissions = (permissions = [], company_brand = {}) => {
  const profile = {
    data: {
      permissions,
      company_brand,
    },
  }
  setLocalStorage('evl-profile', profile);
}
export const onHoverLabel = async (label) => {
  setNotMobileWidth();
  let description = await screen.findAllByText(label);
  expect(description.length).toBe(1);
  userEvent.hover(description[0]);
  description = screen.getAllByText(label);
  expect(description.length).toBe(2);
}
export const getSelectInput = (node) => {
  const childList = node.children;
  for (const child of childList) {
    if (child.tagName === 'INPUT') {
      return child;
    }
    if (child.children.length > 0) {
      const input = getSelectInput(child);
      if (input) return input; 
    }
  }
  return null;
} 
export const getSelect = (node) => {
  const childList = node.children;
  for (const child of childList) {
    if (child.tagName === 'SELECT') {
      return child;
    }
    if (child.children.length > 0) {
      const select = getSelect(child);
      if (select) return select; 
    }
  }
  return null;
} 
export const selectTab = (index) => {
  const tab = screen.getByRole('tablist').children[index];
  fireEvent.click(tab);
}

export const emptyMockResponse = async (expectedResponse = { data: [] }) => {
  return ({
    data: {
      ...expectedResponse
    }
  })
}

export const getQueryObject = (url) => qs.parse(url?.split('?')[1]);

export const openSingleSelector = () => {
  const selector = document.querySelector('.select__control');
  userEvent.click(selector);
}

export const selectValueInSingleSelector = (optionNumber) => {
  openSingleSelector();

  const options = [...document.getElementsByClassName('select__option')];
  userEvent.click(options[optionNumber - 1]);
}

export const getSingleSelectorOptionList = () => [...document.getElementsByClassName('select__option')];

export const generateData = (data) => ({ data: { ...data } });

export const rollbarConfigCheckIgnore = (isUncaught, args, payload) => {
  // ignore rollbar error ChunkLoadError
  if(args[0] && args[0]['name'] === 'ChunkLoadError') {
    if (window.localStorage) {
      // reload GTQ page one time
      if (! localStorage.getItem('reloadGTQPage')) {
          localStorage['reloadGTQPage'] = true;
          // reload page on timeout to allow function to return the value
          setTimeout(() => {
            window.location.reload(true);
          }, 500);
      } else {
          localStorage.removeItem('reloadGTQPage');
      }
    }
    return true;
  } else {
    return false;
  }
}
