import React from 'react'
import { hasPermission } from 'utils';

const ReleaseData = ({ version, permissions }) => {

  const isShowAllData = hasPermission(['AnyResource'], permissions);
  
  return (
    isShowAllData ?
    <>
      <div>
        Web:
      </div>
      <div>
        Release date: {process.env.REACT_APP_RELEASE_DATE}
      </div>
      <div>
        Release number: {process.env.REACT_APP_RELEASE_NR}
      </div>
      <div className="mb-2">
        Release versions: {process.env.REACT_APP_RELEASE_VER}
      </div>
      <div>
        Back:
      </div>
      <div>
        Release date: {version?.release_date}
      </div>
      <div>
        Release number: {version?.release_nr}
      </div>
      <div>
        Release versions: {version?.release_ver}
      </div>
    </>
    :
    <>
      <div>
        Client: v{process.env.REACT_APP_RELEASE_VER ?? 0} ({process.env.REACT_APP_RELEASE_NR ?? 0})
      </div>
      <div>
        Server: v{version?.release_ver ?? 0} ({version?.release_nr ?? 0})
      </div>
    </>
  )
}

export default ReleaseData